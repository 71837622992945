import { Identifiable, isIdentifiable } from '@/api/interfaces/identifiable'
import { AxiosRequestConfig } from 'axios'
import { useAxios, useAxiosHateoasPaginated } from './axios'

export const repository = <T extends Identifiable, S>(version: string, resource: string) => {
  const useGetAll = () => {
    const client = useAxiosHateoasPaginated<T>({
      method: 'get',
      url: `${version}/${resource}`,
    })
    const getAll = (cfg: AxiosRequestConfig = {}) => client.exec(cfg)
    return {
      ...client,
      getAll,
    }
  }

  const useGetOne = () => {
    const client = useAxios<T>({
      method: 'get',
      url: `${version}/${resource}`,
    })
    const getOne = (id: number, cfg: AxiosRequestConfig = {}) =>
      client.exec({ ...cfg, url: `${version}/${resource}/${id}` })

    return {
      ...client,
      getOne,
    }
  }

  const useCreate = () => {
    const client = useAxios<T>({
      method: 'post',
      url: `${version}/${resource}`,
    })
    const create = (data: S, cfg: AxiosRequestConfig = {}) => client.exec({ ...cfg, data })

    return {
      ...client,
      create,
    }
  }

  const useUpdate = () => {
    const client = useAxios<T>({
      method: 'put',
    })
    const update = (id: number, data: T, cfg: AxiosRequestConfig = {}) =>
      client.exec({ ...cfg, data, url: `${version}/${resource}/${id}` })

    return {
      ...client,
      update,
    }
  }

  const useSave = () => {
    const client = useAxios<T>()
    const save = (data: S | T, cfg: AxiosRequestConfig = {}) =>
      client.exec({
        ...cfg,
        data,
        url: isIdentifiable(data) ? `${version}/${resource}/${data.id}` : `${version}/${resource}`,
        method: isIdentifiable(data) ? 'put' : 'post',
      })

    return {
      ...client,
      save,
    }
  }

  const useRemove = () => {
    const client = useAxios<void>({
      method: 'delete',
    })

    const remove = (id: number) => client.exec({ url: `${version}/${resource}/${id}` })

    return {
      ...client,
      remove,
    }
  }

  return {
    useGetAll,
    useGetOne,
    useCreate,
    useUpdate,
    useSave,
    useRemove,
  }
}
